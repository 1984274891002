// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #041525;
  --ion-color-primary-rgb: 4,21,37;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #041221;
  --ion-color-primary-tint: #1d2c3b;
  
  --ion-color-secondary: #03A381;
  --ion-color-secondary-rgb: 3,163,129;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #038f72;
  --ion-color-secondary-tint: #1cac8e;

  --ion-color-tertiary: #56506F;
  --ion-color-tertiary-rgb: 86,80,111;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #4c4662;
  --ion-color-tertiary-tint: #67627d;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-lightblue: #f2f9fa;
  --ion-color-lightblue-rgb: 242,249,250;
  --ion-color-lightblue-contrast: #665546;
  --ion-color-lightblue-contrast-rgb: 102,85,70;
  --ion-color-lightblue-shade: #d5dbdc;
  --ion-color-lightblue-tint: #f3fafb;

  --ion-color-blue: #005587;
  --ion-color-blue-rgb: 0,85,135;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255,255,255;
  --ion-color-blue-shade: #004b77;
  --ion-color-blue-tint: #1a6693;

  --ion-color-brand0: #03A381;
  --ion-color-brand0-rgb: 3,163,129;
  --ion-color-brand0-contrast: #ffffff;
  --ion-color-brand0-contrast-rgb: 255,255,255;
  --ion-color-brand0-shade: #038f72;
  --ion-color-brand0-tint: #1cac8e;

  --ion-color-brand1: #4fba6f;
  --ion-color-brand1-rgb: 79,186,111;
  --ion-color-brand1-contrast: #ffffff;
  --ion-color-brand1-contrast-rgb: 255,255,255;
  --ion-color-brand1-shade: #46a462;
  --ion-color-brand1-tint: #61c17d;

  --ion-color-brand2: #f04949;
  --ion-color-brand2-rgb: 240,73,73;
  --ion-color-brand2-contrast: #ffffff;
  --ion-color-brand2-contrast-rgb: 255,255,255;
  --ion-color-brand2-shade: #d34040;
  --ion-color-brand2-tint: #f25b5b;

  --ion-color-brand3: #f8be3b;
  --ion-color-brand3-rgb: 248,190,59;
  --ion-color-brand3-contrast: #000000;
  --ion-color-brand3-contrast-rgb: 0,0,0;
  --ion-color-brand3-shade: #daa734;
  --ion-color-brand3-tint: #f9c54f;

.ion-color-brand0 {
  --ion-color-base: var(--ion-color-brand0);
  --ion-color-base-rgb: var(--ion-color-brand0-rgb);
  --ion-color-contrast: var(--ion-color-brand0-contrast);
  --ion-color-contrast-rgb: var(--ion-color-brand0-contrast-rgb);
  --ion-color-shade: var(--ion-color-brand0-shade);
  --ion-color-tint: var(--ion-color-brand0-tint);
}

.ion-color-brand1 {
  --ion-color-base: var(--ion-color-brand1);
  --ion-color-base-rgb: var(--ion-color-brand1-rgb);
  --ion-color-contrast: var(--ion-color-brand1-contrast);
  --ion-color-contrast-rgb: var(--ion-color-brand1-contrast-rgb);
  --ion-color-shade: var(--ion-color-brand1-shade);
  --ion-color-tint: var(--ion-color-brand1-tint);
}

.ion-color-brand2 {
  --ion-color-base: var(--ion-color-brand2);
  --ion-color-base-rgb: var(--ion-color-brand2-rgb);
  --ion-color-contrast: var(--ion-color-brand2-contrast);
  --ion-color-contrast-rgb: var(--ion-color-brand2-contrast-rgb);
  --ion-color-shade: var(--ion-color-brand2-shade);
  --ion-color-tint: var(--ion-color-brand2-tint);
}

.ion-color-brand3 {
  --ion-color-base: var(--ion-color-brand3);
  --ion-color-base-rgb: var(--ion-color-brand3-rgb);
  --ion-color-contrast: var(--ion-color-brand3-contrast);
  --ion-color-contrast-rgb: var(--ion-color-brand3-contrast-rgb);
  --ion-color-shade: var(--ion-color-brand3-shade);
  --ion-color-tint: var(--ion-color-brand3-tint);
}

/*
 * Dark Colors
 * -------------------------------------------
 */

body.dark {
  --ion-color-primary: #428cff;
  --ion-color-primary-rgb: 66,140,255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80,200,255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106,100,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47,223,117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255,213,52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255,73,97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244,245,248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0,0,0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34,36,40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255,255,255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */

.ios body.dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0,0,0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #000000;

  --ion-card-background: #1c1c1d;
}

.ios body.dark ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}


/*
 * Material Design Dark Theme
 * -------------------------------------------
 */

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18,18,18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;
}

/** OHN CSS Variables **/

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255,255,255;

  --ion-text-color: #4b4b45;
  --ion-text-color-rgb: 75,75,69;

  --chop-color-white: #fbfbfb;
  --ohn-logo-width: 4rem;
  --ohn-seal-width: 7rem;
}